document.addEventListener('DOMContentLoaded', () => {
    const support = document.querySelector('.side-menu-desktop .support'),
        login = document.querySelector('.side-menu-desktop .login'),
        supportTrigger = document.querySelector('.side-menu-desktop .support .trigger'),
        loginTrigger = document.querySelector('.side-menu-desktop .login .trigger'),
        contactTrigger = document.querySelector('.side-menu-desktop .contact .trigger')

    var supportClick = false,
        loginClick = false

    supportTrigger.addEventListener('click', (e) => {
        e.preventDefault()

        if (!supportClick) {
            support.style.transform = 'translateX(0)'
            document.querySelector('.support .links').style.zIndex = '5'
            supportTrigger.querySelector('img').style.display = 'none'
            supportTrigger.querySelector('span').style.display = 'none'
            supportTrigger.querySelector('.close').style.display = 'block'
            supportClick = true
        } else {
            support.style.transform = 'translateX(240px)'
            document.querySelector('.support .links').style.zIndex = '0'
            supportTrigger.querySelector('img').style.display = 'block'
            supportTrigger.querySelector('span').style.display = 'block'
            supportTrigger.querySelector('.close').style.display = 'none'
            supportClick = false
        }
    })


    loginTrigger.addEventListener('click', (e) => {
        e.preventDefault()

        if (!loginClick) {
            login.style.transform = 'translateX(0)'
            document.querySelector('.login .links').style.zIndex = '5'
            loginTrigger.querySelector('img').style.display = 'none'
            loginTrigger.querySelector('span').style.display = 'none'
            loginTrigger.querySelector('.close').style.display = 'block'
            loginClick = true
        } else {
            login.style.transform = 'translateX(240px)'
            document.querySelector('.login .links').style.zIndex = '1'
            loginTrigger.querySelector('img').style.display = 'block'
            loginTrigger.querySelector('span').style.display = 'block'
            loginTrigger.querySelector('.close').style.display = 'none'
            loginClick = false
        }
    })


})